




























import { Component, Vue, Prop } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import TableBase from '@/components/atoms/v3/TableBase1110.vue'
import RadioBase from '@/components/atoms/RadioBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import _ from 'lodash'

export type DifficultInitial = {
  branch_id: number
  levels: number[]
  class_mode_code: string
  class_category_code: string
  subject_code: string
}

type DifficultCheckBoxItem = {
  value: number
  checked: boolean
  label: string
  disabled?: boolean
  isEmpty?: boolean
}

export type DifficultTableItem = {
  subjectName: string
  subjectId: number
  subjectCode: string
  difficult: string
  difficult1: DifficultCheckBoxItem
  difficult2: DifficultCheckBoxItem
  difficult3: DifficultCheckBoxItem
  setDifficult?: any
}

export type ActiveSubject = {
  subjectName: string;
  subjectCode: string;
  subjectId: number;
}
export type Subject = {
  name: string;
  code: string;
  id: number;
}

export const DIFFICULT_TYPES = {
  test: 'RK',
  exercise: 'EN',
} as const

const SUBJECTS = [
  {
    subjectId: 1,
    subjectName: '算数/数学',
    subjectCode: 'su',
  },
  {
    subjectId: 2,
    subjectName: '英語',
    subjectCode: 'ei',
  },
  {
    subjectId: 3,
    subjectName: '国語',
    subjectCode: 'ko',
  },
  {
    subjectId: 4,
    subjectName: '理科',
    subjectCode: 'ri',
  },
  {
    subjectId: 5,
    subjectName: '社会',
    subjectCode: 'sh',
  },
]
@Component({
  components: {
    ButtonBase,
    RadioToggle,
    PopupBase,
    TableBase,
    RadioBase,
    RadioGroup,
  },
})
export default class LevelDifficult extends Vue {
  @Prop({ default: [] })
  difficultInitial!: DifficultInitial[]
  @Prop({ default: false })
  isCustomMode!: boolean
  @Prop()
  handleSubmit!: (
    difficultType: string,
    difficultData: { RK?: DifficultTableItem[]; EN?: DifficultTableItem[] }
  ) => void

  private difficult_types = DIFFICULT_TYPES
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isShowModal = false

  private difficultTypeRadioItems = [
    { label: '理解度テスト', value: DIFFICULT_TYPES.test },
    { label: '演習問題', value: DIFFICULT_TYPES.exercise },
  ]

  private difficultType = DIFFICULT_TYPES.test

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.getActiveSubjects()
    await this.getDifficultItems()
    Vue.prototype.$loading.complete()
  }

  private levelList = [
    { value: 1, name: '難易度１' },
    { value: 2, name: '難易度２' },
    { value: 3, name: '難易度３' },
  ]

  private difficultItems: { RK?: DifficultTableItem[]; EN?: DifficultTableItem[] } | null = null
  private difficultCheckboxs = ['difficult1', 'difficult2', 'difficult3']
  private difficultFields = [
    { key: 'subjectName', label: '教科' },
    { key: 'difficult', label: '難易度設定' },
    { key: 'difficult1', label: '' },
    { key: 'difficult2', label: '' },
    { key: 'difficult3', label: '' },
  ]
  private activeSubjects: ActiveSubject[] = []

  private openPopup() {
    this.isShowModal = true
  }

  private handleOk() {
    if (!this.difficultItems?.[this.difficultType]?.length) return
    this.handleSubmit(this.difficultType, this.difficultItems)
    this.isShowModal = false
  }

  private handleCancel() {
    this.isShowModal = false
    this.getDifficultItems(true)
  }

  private get isCreateMode() {
    return this.$route.path === "/teacher/setting/create-mode"
  }
  private get isModeDefault() {
    return this.$route.path === "/teacher/setting/mode-default"
  }
  private async getActiveSubjects() {
    const GDLS_SERVICE = 'GDLS'

    const response = await Vue.prototype.$http.httpWithToken.get('/v3/active_subjects', {
      params: { branch_id: this.branchId, service_code: GDLS_SERVICE },
    })
    this.activeSubjects = response?.data
  }

  public getDifficultItems(isCancel?: boolean) {
    if (!this.activeSubjects) return
    this.difficultItems = [DIFFICULT_TYPES.test, DIFFICULT_TYPES.exercise].reduce((result, item) => {
      result[item] = SUBJECTS?.map(({ subjectCode, subjectId, subjectName }: ActiveSubject) => {
        const currentDifficultSubject = this.difficultInitial?.find((difficultItem) => {
          return difficultItem.subject_code === subjectCode && difficultItem.class_category_code === item
        })
        const checkedBySubject = (difficult: number) => {
          if (this.isCreateMode && !isCancel) {
            return true
          }
          return currentDifficultSubject ? currentDifficultSubject?.levels.includes(difficult) : false
        }
        const isEmpty = !this.activeSubjects.find((activeSubject) => activeSubject.subjectCode === subjectCode) && this.isModeDefault
        return {
          subjectName,
          subjectId,
          subjectCode,
          difficult: '難易度',
          difficult1: {
            value: 1,
            checked: checkedBySubject(1),
            label: '1',
            isEmpty,
          },
          difficult2: {
            value: 2,
            checked: checkedBySubject(2),
            label: '2',
            isEmpty,
          },
          difficult3: {
            value: 3,
            checked: checkedBySubject(3),
            label: '3',
            isEmpty,
          },
        }
      })
      return result
    }, {})
    if (this.isCreateMode) {
      this.setDefaultDifficultSetting()
    }
  }

  private setDefaultDifficultSetting() {
    Object.values(DIFFICULT_TYPES).forEach((value) => {
      if (!this.difficultItems?.[value]?.length) return
      this.handleSubmit(value, this.difficultItems)
    })
  }
}
