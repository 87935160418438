




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'

@Component({
  components: {
    ButtonBase,
    CheckboxSquare,
    RadioToggle,
  },
})
export default class TableBase extends Vue {
  private currentPage = 1

  private small = false

  private striped = false

  private hover = true

  private headVariant = null

  private bordered = false

  private borderless = false

  @Prop()
  perPage?: number

  @Prop()
  items!: []

  @Prop()
  fields!: []

  @Prop()
  buttons?: []

  @Prop()
  checkboxs?: []

  @Prop()
  toggles?: []

  @Prop()
  multipleLines?: []

  @Prop({ default: 0 })
  number?: number

  @Prop()
  links?: []

  get rows(): number {
    return this.items.length
  }

  private getCell(value: string): string {
    return `cell(${value})`
  }

  private getHead(value: string): string {
    return 'head(' + value + ')'
  }

  private checkVariant(value: { [key: string]: string }): string {
    if (value.variant) {
      return value.variant
    }
    return 'outline-primary'
  }

  private checkurl(value: string): string | null {
    if (value) {
      return value
    }
    return null
  }

  private checkclick(func: any): string | null {
    if (func) {
      return func(this.number)
    }
    return null
  }
}
